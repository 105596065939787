<template>
	<!-- [D] 상단 고정 메뉴일 경우 nav--fixed 클래스 추가 -->
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left--white">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text white">
					헐리우드룸
				</span>
			</p>
			<a href="#" class="nav__btn nav__btn--map">
				<!--<i class="icon icon-share--white"></i>-->
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container">
		<div id="sub" class="sub detail">
			<!-- gallery -->
			<section class="gallery">
				<div class="gallery__slider">
					<swiper @slideChange="setSlideCurrentIndex" data-currentnum=".gallery__currentnum--1" :autoplay='{"delay": 3000, "disableOnInteraction": false}' :loop="true">
						<swiper-slide>
							<div class="gallery__imgbox noneimage">
								<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/tempsquare01.jpg') + ')'}">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="gallery__imgbox noneimage">
								<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/tempsquare01.jpg') + ')'}">
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</div>
				<div class="box">
					<div class="gallery__meta gallery__meta--view">
						<span class="gallery__badge">
							<strong class="gallery__currentnum gallery__currentnum--1">1</strong>
							<i class="gallery__dot"></i>
							<small>30</small>
						</span>
					</div>
				</div>
			</section>
			<!-- contact -->
			<section class="content">
				<div class="box">
					<div class="detail__wrap">
						<!-- 숙소 기본정보 -->
						<header class="detail__header">
							<div class="detail__contact">
								<div class="detail__slogan">
									역삼역 1번 출구에서 145m
								</div>
								<div class="detail__sns">
									<button type="button">
										<i class="icon icon-kakao">카카오톡</i>
										카톡
									</button>
									<a href="tel:010">
										<i class="icon icon-tel"></i>
										전화
									</a>
								</div>
							</div>
						</header>
						<!-- 숙소 상세정보 -->
						<div class="detail__body">
							<!-- 숙소 추가 정보 -->
							<article class="detail__meta">
								<ul class="search__list">
									<li>
										<div class="search__inner">
											<i class="icon icon-date">숙박 일자</i>
											<input type="text" class="search__ipt active" readonly value="11.11(목) ~ 11.12(금),9박">
											<span>날짜 변경</span>
										</div>
									</li>
								</ul>
							</article>
							<!-- 숙소 타입 -->
							<article class="detail__type">
								<div class="type">
									<!-- [REST] 숙박 정보 표시 -->
									<ul class="type__list">
										<li>
											<div class="type__title">
												숙박
											</div>
											<div class="type__wrap">
												<div class="type__inner">
													<ul class="type__info">
														<li>
															<span>체크인</span>
															<strong>15:00</strong> 부터
														</li>
														<li>
															<span>체크아웃</span>
															<strong>10:00</strong> 까지
														</li>
													</ul>
												</div>
												<div class="type__price">
													<div class="info-price">
														<div class="info-price__sale">
															<strong>14%</strong>
															<span>35,000</span>
														</div>
														<div class="info-price__current">
															<strong>30,000</strong>원
														</div>
													</div>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</article>
							<!-- 객실 정보 -->
							<!-- [REST] 관리자 페이지에서 직접 입력 -->
							<article class="detail__info">
								<!-- 객실 시설 -->
								<div class="detail__info-item">
									<h3 class="detail__subtitle" style="margin-bottom:0">
										객실시설
									</h3>
									<div class="detail__description description">
										<div class="detail__feature">
											<span>피트니스</span>
											<span>사우나</span>
											<span>스파</span>
											<span>수영장</span>
											<span>골프 연승장</span>
											<span>살롱</span>
											<span>컨퍼런스 룸</span>
											<span>PC</span>
											<span>공기청정기</span>
											<span>바</span>
											<span>레스토랑</span>
										</div>
									</div>
								</div>
								<!-- 객실 소개 -->
								<div class="detail__info-item">
									<h3 class="detail__subtitle">
										기본정보
									</h3>
									<p class="detail__description description">
										해당 객실은 차량이용고객은 예약이 불가하오니,
										이용에 참고부탁드립니다.
									</p>
								</div>
								<!-- 예약 공지 -->
								<div class="detail__info-item">
									<h3 class="detail__subtitle">
										예약공지
									</h3>
									<p class="detail__description description">
										주차는 1실 1주차 입니다.<br>
										객실요금은 2인 입실 기준이며, 파티룸 등 특수객실의 경우, 직접 입실 인원 확인이 필요합니다.<br>
										미성년자의 입실 가능여부는 직접 제휴점에 확인 후
										예약 진행하시기 바랍니다.<br>
										미성년자 혼숙및 3인이상 성인혼숙예약으로 인해
										발생하는 입실 거부에 대해서는 취소/환불이
										불가합니다. 제휴점 사정에 의한 취소 발생 시 100% 환불 처리 됩니다.<br>
										제휴점 사정으로 객실 정보가 수시로 변경될 수 있습니다. 이로 인한 불이익은 당사가 책임지지 않습니다.
									</p>
								</div>
								<!-- 취소 규정 -->
								<div class="detail__info-item">
									<h3 class="detail__subtitle">
										취소규정
										<a href="#" class="btn-detaillink">
											자세히 보기
										</a>
									</h3>
									<p class="detail__description description">
										당일 체크인 상품으로 취소 및 환불 불가합니다. <br>
										단, 모텔의 경우 예약 완료 시점부터 1시간 이내 전액 취소가 가능합니다. (마이페이지 → 예약 내역)
										예약완료 후 1시간 이내라도 입실 시간이 경과된 경우, 취소 및 환불 불가합니다.
									</p>
								</div>
							</article>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<!-- navigation -->
	<!-- [D] 대실,숙박 불가일 경우 btn-disabled 클래스 사용 -->
	<div id="navigation" class="navigation">
		<div class="btns btns--centered">
			<a href="#" class="btn btn-confirm">
				숙박예약
			</a>
		</div>
	</div>
	<!-- //navigation -->
</template>

<script>
export default {
	methods : {
		/* 현재 슬라이드 넘버 지정하기 */
		setSlideCurrentIndex : (swiper) => {
			var target = swiper.$el[0].getAttribute('data-currentnum'),
				index = swiper.realIndex;
			document.querySelector(target).innerHTML = index + 1;
		}
	}
}
</script>